var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-1 mb-4 pl-4 pr-4"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('VerticalTable',{attrs:{"cols":_vm.main_info_cols,"items_info":_vm.organization},scopedSlots:_vm._u([{key:"field-item-date_registration_company",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.organization.date_registration_company))+" ")])]},proxy:true},(_vm.is_entrepreneur)?{key:"field-item-ip_scan_file",fn:function(){return [_c('td',[_c('DownloadBtn',{staticClass:"ml-n4",attrs:{"src":_vm.organization.ip_scan_file},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" Скачать скан свидетельства о регистрации ИП ")]},proxy:true}],null,false,845182973)})],1)]},proxy:true}:null,(_vm.is_entrepreneur)?{key:"text-item-ip_scan_file",fn:function(){return [_c('td',[_vm._v(" ИП ")])]},proxy:true}:null,{key:"field-item-attorney_file",fn:function(){return [_c('td',[(_vm.organization.attorney_file)?_c('DownloadBtn',{staticClass:"ml-n4",attrs:{"src":_vm.organization.attorney_file},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" Скачать скан доверенности ")]},proxy:true}],null,false,728470617)}):_vm._e()],1)]},proxy:true},{key:"field-item-basis_document",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm.acts_on_basis_translate[_vm.organization.basis_document] || '')+" ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"3"}},[_c('VerticalTable',{attrs:{"cols":_vm.contacts_cols,"items_info":_vm.organization,"single_cell_render":""},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Контакты ")]},proxy:true},_vm._l((_vm.contacts_cols),function(col,i){return {key:_vm.slot_name(col.field),fn:function(ref){
var item = ref.item;
return [_c('td',{key:i},[_vm._v(" "+_vm._s(_vm.organization[item.field])+" "),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item.text)}})])]}}})],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"3"}},[_c('VerticalTable',{attrs:{"cols":_vm.edo_cols,"items_info":_vm.organization,"single_cell_render":""},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Данные из ЭДО ")]},proxy:true},{key:"single-item-edo_operator",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.get_edo_field(item.field))+" "),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item.text)}})])]}},{key:"single-item-edo_contact_person",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.get_edo_field(item.field))+" "),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item.text)}})])]}},{key:"single-item-edo_telephone",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.get_edo_field(item.field))+" "),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item.text)}})])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }